import React from 'react';
import Layout from '@/layouts/main';
import { Mixpanel } from '@/utils';
import Science from '@/views/science';

export default function SciencePage() {
  React.useEffect(() => Mixpanel.track(`View Methodology Page`), []);
  return (
    <Layout
      title="Our Methodology - Transforming your health through your gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
      active="science"
    >
      <Science />
    </Layout>
  );
}
